import React from 'react'
import { Card, Container } from 'reactstrap';

export default function Books() {
    let javaScriptUrl = `https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/public/474769c9d607fb53182f4f4136d54796d78a9750.pdf`;
      let htmlcssUrl = `https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/public/07f8f6acfed596c4487bed88158dcf08cae98b7b.pdf`;
    let reactUrl = `https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/public/1d6de3005ed1010452f16328594aac90c94dc9d3.pdf`
  return (
    <Container>
          <div>
        <h1>MERN Stack Basics</h1>
        <p>Complete Books</p>
        <div>
           <Card style={{width:300}}>
           <img src={require('../components/books/1.jpg')} alt="javascript" />
           <a href={javaScriptUrl}>VIEW BOOK</a>
           </Card>
        </div>
        <hr />
        <div>
       <Card style={{width:300}}>
       <img src={require('../components/books/2.jpg')} alt="html" />
       <a href={htmlcssUrl}>
       VIEW BOOK
       </a>
       </Card>
        </div>
        <hr />
        <div>
       <Card style={{width:300}}>
       <img src={require('../components/books/3.jpg')} alt="react" />
       <a href={reactUrl}>
       VIEW BOOK
       </a>
       </Card>
        </div>
    </div>
    </Container>
  )
}
