import React from 'react'

const Features = (props) => {
    return (
        <section className="features-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-sheriff"></i>
                                </div>
                                <h3>Business Consulting</h3>
                                <p>
                                We help businesses and organizations manage their technology use. 
                                This can involve assessing elements of a company's computer systems like cybersecurity,
                                 software performance and data and analytics that can affect how a business might benefit 
                                 from the technology they use.
                                </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-diamond"></i>
                                </div>
                                <h3>Market Research</h3>
                                <p>
                                Feel confident your recruitment process is a positive experience for respondents and see better 
                                outcomes as a result. 
                                Plus, get the most out of your projects with a consultative approach from our expert team.
                                </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-idea"></i>
                                </div>
                                <h3>Thaught Leadership</h3>
                                <p>Trust an expert consulting team to connect you with even the hardest to reach qualitative healthcare,
                                     B2B, or consumer respondents from around the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;