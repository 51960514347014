import React from 'react'

export default function Cancel() {
  return (
    <div style={{padding:20}}>
        <h1>Something went wrong</h1>
        <p><a href='/'>Home Page</a></p>
    </div>
  )
}
