import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'
import simg5 from '../images/service-single/img-5.jpg'
import simg6 from '../images/service-single/img-3.jpg'


const Services = [
    {
        Id: '1',
        sImg:simg,
        sTitle:'Training',
        slug:'Market-Research',
        description:'Every grad is paired with a Career Coach for up to 180 days after graduation. They help with reviews of your resume, building an online brand, mock interviews, and more.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-gift',
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle:'Continuous Career Prep Curriculum',
        slug:'Corporate-Finance',
        description:'Our Career Prep curriculum includes access to over 50 lessons on how to launch a new career in tech, including a variety of resources, email templates, and videos.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-suitcase',
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Exclusive Alumni Job Portal Access',
        slug:'Advanced-Analytics',
        description:'With MCL, an easy-to-use job tracking tool, you will receive exclusive access to the MERN CODE School Job Portal, featuring roles shared by our employer network.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-stats',
    },
   
]    

export default Services;