import React from 'react';



const Testimonial = (prop) => {

    return (
        <section className={`testimonials-section section-padding ${prop.tClass}`}>
            <h2 className="hidden">Testimonials</h2>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="testimonials-area">
                            <p> 
                            The first rule of any technology used in a business is that automation applied to an efficient operation will magnify the efficiency. 
                            The second is that automation applied to an inefficient operation will magnify the inefficiency.
                            </p>
                            <span className="quoter">Bill Gates</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;