import React from 'react'

const WorkProcess = (props) => {
    return (
        <section className="work-process-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title">
                            <span>Our wrok process</span>
                            <h3>
                                Training Process
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col col-lg-10 offset-lg-1">
                        <div className="work-process-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-idea"></i>
                                </div>
                                <h3>180 Days of Career Coaching</h3>
                                <p> Every grad is paired with a Career Coach for up to 180 days after graduation. 
                                    They help with reviews of your resume, building an online brand, mock interviews, and more. </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-diamond"></i>
                                </div>
                                <h3>Continuous Career Prep Curriculum</h3>
                                <p> Our Career Prep curriculum includes access to over 50 lessons on how to launch a new career in tech, 
                                    including a variety of resources, email templates, and videos. </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-sheriff"></i>
                                </div>
                                <h3>Exclusive Alumni Job Portal Access</h3>
                                <p> With Huntr, an easy-to-use job tracking tool, you will receive exclusive access to the MERN CODE School Job Portal, 
                                    featuring roles shared by our employer network.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkProcess;