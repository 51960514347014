import { getDatabase, onValue, ref } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const Contacts = () => {
    const [contacts,setContacts] = useState([]);

    const navigate = useNavigate();

    const memoizedContacts = useCallback(() => {
        onValue(ref(getDatabase(),`contacts`),(cts) => {
            let contactsArray = []
            cts.forEach(c => {
                contactsArray.push(c)
            })
            setContacts(contactsArray.reverse())
        })
    },[contacts])

    useEffect(() => {
        memoizedContacts()
    },[])

    return (
        <section className="mission-vision-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="title-area">
                            <h1>
                            Contacts
                            </h1>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        contacts?.map((c,i) => (
                            <div 
                            key={i}
                            className="col col-xs-12">
                                <div className="mission-vision-grids clearfix">
                                    <h3>{i+1}. {c.val().name}</h3>
                                    <p><a href={`mailto:${c.val().email}`}>{c.val().email}</a></p>
                                    <p>{c.val().subject}</p>
                                    <p><a href={`tel:${c.val().phone}`}>{c.val().phone}</a></p>
                                    <p>{c.val().message}</p>
                                    <p>{c.val().date}</p>
                                    <hr/>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <button onClick={() => navigate('/')} className="btn btn-primary">HOME PAGE</button>
            </div>
        </section>
    );
}

export default Contacts;