import { getDatabase, onValue, push, ref, remove } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'reactstrap'

export default function RegisteredStudents() {
    const [data,setData] = useState([]);

    const fetchRegisteredStudentsDataAsync = useCallback(() => {
        onValue(ref(getDatabase(),`enrollment`),(snapShot) => {
            let array = [];
            snapShot.forEach(snap => {
                array.push(snap)
            })
            setData(array)
        })
    },[data])

    useEffect(() => {
        fetchRegisteredStudentsDataAsync()
    },[])

    function removeStudent(d){
        push(ref(getDatabase(),`enrollment-trash`),{
            ...d.val()
        })
        remove(ref(getDatabase(),`enrollment/${d.key}`))
        alert('Student data removed successfully')
    }


  return (
    <Container>
        <h1>Enrolled Students</h1>
        <hr />
        {
            data?.map((d,i) => (
                <div key={i}>
                    <h3>{d?.val()?.firstName} {d?.val()?.lastName}</h3>
                    <p>{d?.val()?.email}</p>
                    <p>{d?.val()?.phone}</p>
                    <p>{d?.val()?.classOption}</p>
                    <p>{d?.val()?.date}</p>
                    <br />
                    <button className='btn btn-danger' onClick={() => removeStudent(d)}>delete</button>
                    <hr />
                </div>
            ))
        }
    </Container>
  )
}
