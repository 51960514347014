import React from 'react'

export default function Success() {
  return (
    <div style={{padding:20}}>
        <h1>Success</h1>
        <p>Something went wrong</p>
        <br/>
        <a href='/'>Home Page</a>
    </div>
  )
}
