export const codemernllc = {
    title:'MERN CODE LIMITED',
    description:'MERN CODE Academy',
    technology:'MERN STACK',
    languages:'MongoDB, Express, React and Node',
    established:'08/07/2024',
    address:'450 Park Avenue S, New York, NY 10016',
    phone:'+1-845-554-6628',
    email:'merncodeltd@gmail.com',
    altEmail:'admin@merncode.com',
    website:'https://www.merncode.com',
    ein:'99-4334964'
}

