import React from 'react'
import abimg from '../../images/about-2.jpg'
import VideoModal from '../ModalVideo/VideoModal'
import { codemernllc } from '../../api/codemernllc'


const About = (props) => {
    return (
        <section className="about-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 order-lg-1 order-2 col-12">
                        <div className="img-holder">
                            <img src={abimg} alt="" />
                            <div className="experience-text">
                                10 Years of <span>Experience</span>
                            </div>
                            <VideoModal />
                        </div>
                    </div>
                    <div className="col col-lg-6 order-lg-2 order-1 col-12">
                        <div className="section-title-s2">
                            <span>We are Coding Bootcamp!</span>
                            <h2>We help you to succeed</h2>
                        </div>
                        <div className="about-content">
                            <h4>
                            We’ll help you write your first line of code in minutes.
                            </h4>
                            <p>
                            Learn in-demand Full Stack Software Development in a flexible and mentored online and in person program.
                                </p>
                            <h5>Phone: {codemernllc.phone}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;