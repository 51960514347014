import React from "react";
import abimg1 from '../../images/about/img-1.jpg'
import abimg2 from '../../images/about/img-2.jpg'
import abimg3 from '../../images/about/img-3.jpg'


const MissionVission = () => {
    return (
        <section className="mission-vision-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="title-area">
                            <h2>
                            Take our software development courses on campus or online. 
                            We’ve helped thousands of MERN CODE Academy grads transform their lives and the companies where they work.
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="mission-vision-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg1} alt="" />
                                </div>
                                <h3>Our mission</h3>
                                <p>
                                   Changing lives through education is our mission.
                                    Our courses provide an immersive, outcomes-driven curriculum for students looking to launch a career in 
                                    Software Engineering, Cybersecurity, Data Science, or Product Design.
                                    
                                </p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg2} alt="" />
                                </div>
                                <h3>Our vision</h3>
                                <p>
                                As a leader in the tech industry, CODE MERN Academy believes that education is the best investment you can make in your future.
                                 We’re committed to helping  change lives and the world for the better.
                                </p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg3} alt="" />
                                </div>
                                <h3>Our strategies</h3>
                                <p>
                                Since 2022, CODE MERN ACADEMY has been a leader in the bootcamp industry,
                                 providing market-driven education to help people change their lives.
                                 Courses are offered both online and in-person on campuses across the country.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionVission;