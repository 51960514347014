import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { push, ref, getDatabase } from 'firebase/database';
import emailjs from 'emailjs-com';
import { publicKey, serviceID, templateID } from '../../api/emailjs';

const ApplyForm = () => {

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        options:''
    });
    const [successMessage,setSuccessMessage] = useState('');

    const [name,setName] = useState('');
    const [email,setEMail] = useState('');
    const [subject,setSUbject] = useState('');
    const [phone,setPhone] = useState('');
    const [message,setMessage] = useState('');
    const [options,setOptions] = useState('');

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            console.log(forms);
            push(ref(getDatabase(),`contactedUsers/`),{
                date:new Date().toString(),
                email,
                subject,
                message,
                name,
                phone,
                options
            })
            setSuccessMessage('Form successfully submitted. Thank you')
               // send email
         emailjs.init(publicKey)
         emailjs.sendForm(serviceID,templateID,e.target)
         .then(() => {
             setSuccessMessage('Form successfully submitted. Thank you')
             setName('');
             setEMail('');
             setSUbject('');
             setMessage('');
             setPhone('');
             setOptions('');
             setForms({
                 name: '',
                 email: '',
                 subject: '',
                 phone: '',
                 message: '',
                 options:''
             })
         })
         .catch((err) => {
             console.log(err)
             setSuccessMessage('Something went wrong. Try again later')
         })
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            {
                successMessage && <p style={{fontSize:16,color:'green'}}>{successMessage}</p>
            }
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={name}
                            type="text"
                            name="from_name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Name" />
                        {validator.message('name', name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={email}
                            type="email"
                            name="message"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => setEMail(e.target.value)}
                            placeholder="Your Email" />
                        {validator.message('email', email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={phone}
                            type="phone"
                            name="message"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Your phone" />
                        {validator.message('phone', phone, 'required|phone')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => setSUbject(e.target.value)}
                            value={subject}
                            type="text"
                            name="message">
                            <option>
                            What course are you interested in studying?</option>
                            <option value={`Software Engineering`} selected>Software Engineering</option>
                            <option value={`Front End Development`}>Front End Development</option>
                            <option value={'Back End Development'}>Back End Development</option>
                            <option value={'Mobile App Development'}>Mobile Development</option>
                        </select>
                        {validator.message('subject', subject, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => setOptions(e.target.value)}
                            value={options}
                            type="text"
                            name="message">
                            <option>Choose a class option</option>
                            <option value={`Online`}>Online (remote)</option>
                            <option value={`On Campus`}>On Campus (at School)</option>
                        </select>
                        {validator.message('options', options, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12 comment-area">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        type="text"
                        name="message"
                        placeholder="Tell us about yourself. Why is this course the right next step for you?">
                    </textarea>
                    {validator.message('message', message, 'required')}
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="submit-area">
                        <button type="submit" className="theme-btn">Submit Now</button>
                    </div>
                </div>
            </div>
        </form >
    )
}

export default ApplyForm;