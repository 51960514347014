import pimg1 from '../images/projects/img-1.jpg'
import pimg2 from '../images/projects/img-2.jpg'
import pimg3 from '../images/projects/img-3.jpg'
import pimg4 from '../images/projects/img-4.jpg'
import pimg5 from '../images/projects/img-7.jpg'
import pimg6 from '../images/projects/img-10.jpg'

const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       title:'Market Research',  
       slug:'Market-Research',  
       subTitle:'Consulting',   
    },
    {
       Id:'2',
       pImg:pimg2,
       title:'Investment planning', 
       slug:'Investment-planning', 
       subTitle:'Maketing',    
    },
    {
      Id:'3',
      pImg:pimg3,
      title:'Business Analysis',  
      slug:'Business-Analysis',  
      subTitle:'Business',   
   },
    {
       Id:'4',
       pImg:pimg4,
       title:'Financial Advices',  
       slug:'Financial-Advices',  
       subTitle:'Consulting', 
    }, 
    {
       Id:'5',
       pImg:pimg5,
       title:'Business Analysis',  
      slug:'Business-Analysiss',   
       subTitle:'Consulting', 
    }, 
    {
      Id:'6',
      pImg:pimg6,
      title:'Investment planning', 
      slug:'Investment-plannings', 
      subTitle:'Maketing',    
   },
]

export default Projects;