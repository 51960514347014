// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWWGJDqjduckGOujB_I-O4hVqKFKXY7_s",
  authDomain: "merncodeltd-42625.firebaseapp.com",
  projectId: "merncodeltd-42625",
  storageBucket: "merncodeltd-42625.appspot.com",
  messagingSenderId: "678264230011",
  appId: "1:678264230011:web:213ab8ee25a1d3913392de",
  measurementId: "G-HJWQ53HCL2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app