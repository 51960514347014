import React from 'react'

export default function OnboardSuccess() {
  return (
    <div>
        <h1>Onboard Success</h1>
        <p>You have successfully setup stripe payment account.</p>
        <p>You can go back and use cash out or transfer your payment</p>
    </div>
  )
}
