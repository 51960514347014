import { getDatabase, onValue, ref, remove } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsPhone } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegClock } from "react-icons/fa6";
import { FaLaptopCode } from "react-icons/fa";
import { FaPenAlt } from "react-icons/fa";
import { MdOutlineSchool } from "react-icons/md";

const Candidates = () => {
    const [contacts,setContacts] = useState([]);

    const navigate = useNavigate();

    const memoizedContacts = useCallback(() => {
        onValue(ref(getDatabase(),`contactedUsers/`),(cts) => {
            let contactsArray = []
            cts.forEach(c => {
                contactsArray.push(c)
            })
            setContacts(contactsArray.reverse())
        })
    },[contacts])

    function removeThisInfo(c){
        remove(ref(getDatabase(),`contactedUsers/${c?.key}`))
        alert('Contact deleted')
    }

    useEffect(() => {
        memoizedContacts()
    },[])

    return (
        <section className="mission-vision-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="title-area">
                            <h1>
                            Candidates
                            </h1>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        contacts?.map((c,i) => (
                            <div 
                            key={i}
                            className="col col-xs-12">
                                <div className="mission-vision-grids clearfix">
                                    <h3>{i+1}. {c?.val().name ? c?.val().name : c?.val().username }</h3>
                                    <p><a href={`mailto:${c.val().email}`}><MdOutlineMailOutline /> {c.val().email}</a></p>
                                    <p><a href={`tel:${c.val().phone}`}><BsPhone /> {c.val().phone}</a></p>
                                    <p><FaLaptopCode /> {c.val().subject}</p>
                                    <p><MdOutlineSchool /> {c.val().options}</p>
                                    <p><FaPenAlt /> {c.val().message}</p>
                                    <p><small><FaRegClock /> {c.val().date}</small></p>
                                    <br/>
                                    <button onClick={() => removeThisInfo(c)} className="btn btn-danger">Delete</button>
                                    <hr/>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <button onClick={() => navigate('/')} className="btn btn-primary">HOME PAGE</button>
            </div>
        </section>
    );
}

export default Candidates;